.px-sass-container {
  width: 100%;
  background-color: RGB(var(--px_color_background));
}

.px-v-dashboard-main:has(> .px-frame-view),
.px-v-homepage:has(> .px-frame-view) {
  display: flex;
  justify-content: center;

  .px-frame-view {
    width: var(--px_max_container_width, 1200px);
    @media (max-width: 1200px) {
      width: 100%; 
    }
  }
}

footer {
  width: 100%;
  display: flex;
  justify-content: center;

  .px-footer-inner-container {
    width: var(--px_max_container_width, 1200px);
    padding-left: 38px;
    @media (max-width: 1024px) {
      padding-bottom: 16px;  
    }
  }
}

.px-v-dashboard {
  position: relative;
  min-height: 100vh;
}

.px-v-dashboard main {
  margin-bottom: 80px;
}

a.px-error-logout {
  color: #fff !important;
  text-decoration: underline;
}

.ag-theme-alpine .ag-root-wrapper {
  border: none !important;
}

.ag-theme-alpine .ag-header {
  background-color: RGB(var(--px_color_background));
}

.px-pricing-item-header {
  border-top: 0 !important;
}

.ag-row.ag-row-even.ag-row-pinned.ag-row-level-0.ag-row-position-absolute.ag-row-first {
  background-color: RGB(var(--px_color_background));
}

// .ag-theme-alpine .ag-cell.ag-cell-last-left-pinned:not(.ag-cell-range-right):not(.ag-cell-range-single-cell) {
//   border-right: solid 0px;
// }

.px-offset-bottom-mobile-view {
  &::after {
    content: '';
    width: 100%;
    height: 70px;
    display: block;
  }
}

.px-import-popup {
  z-index: 9999 !important;

  .MuiDialogActions-root {
    padding: 10px 20px 20px 0 !important;

    button:first-of-type {
      margin-right: 20px !important;
    }
  }

  #alert-dialog-description {
    font-size: 1rem;
  }
}

.MuiButtonBase-root.MuiButton-root.MuiButton-outlined {
  color: RGB(var(--px_color_text_primary));
}

a.MuiButton-root {
  //line-height: 35px;
  padding: 0 15px;
}

// a.MuiButton-root:hover,
// a.MuiButton-root:active,
// a.MuiButton-root:visited {
//   color: #fff;
// }

a:not(.MuiButton-root) {
  .MuiButton-label {
    color: RGB(var(--px_color_link));
  }
}

nav {

  a.MuiButton-root:hover,
  a.MuiButton-root:active,
  a.MuiButton-root:visited {
    color: RGB(var(--px_color_link));
  }
}

.offered.MuiChip-root {
  border-color: RGB(var(--px_color_badge_3));

  &.MuiChip-outlined,
  &.MuiChip-outlined:hover,
  &.MuiChip-outlined:focus {
    color: RGB(var(--px_color_badge_3));
    background-color: RGBA(var(--px_color_badge_3), var(--px_alpha_light));
  }

  &.px-filter-on,
  &.px-filter-on:hover,
  &.px-filter-on:focus {
    background-color: RGB(var(--px_color_badge_3));
    color: RGB(var(--px_color_text_on_badge));
  }
}

.buyatlist.MuiChip-root {
  border-color: RGB(var(--px_color_badge_2));

  &.MuiChip-outlined,
  &.MuiChip-outlined:hover,
  &.MuiChip-outlined:focus {
    color: RGB(var(--px_color_badge_2));
    background-color: RGBA(var(--px_color_badge_2), var(--px_alpha_light));
  }

  &.px-filter-on,
  &.px-filter-on:hover,
  &.px-filter-on:focus {
    background-color: RGB(var(--px_color_badge_2));
    color: RGB(var(--px_color_text_on_badge));
  }
}

.accepted.MuiChip-root {
  border-color: RGB(var(--px_color_badge_2));

  &.MuiChip-outlined,
  &.MuiChip-outlined:hover,
  &.MuiChip-outlined:focus {
    color: RGB(var(--px_color_badge_2));
    background-color: RGBA(var(--px_color_badge_2), var(--px_alpha_light));
  }

  &.px-filter-on,
  &.px-filter-on:hover,
  &.px-filter-on:focus {
    background-color: RGB(var(--px_color_badge_2));
    color: RGB(var(--px_color_text_on_badge));
  }
}

.autocheckout.MuiChip-root {
  border-color: RGB(var(--px_color_badge_2));

  &.MuiChip-outlined,
  &.MuiChip-outlined:hover,
  &.MuiChip-outlined:focus {
    color: RGB(var(--px_color_badge_2));
    background-color: RGBA(var(--px_color_badge_2), var(--px_alpha_light));
  }

  &.px-filter-on,
  &.px-filter-on:hover,
  &.px-filter-on:focus {
    background-color: RGB(var(--px_color_badge_2));
    color: RGB(var(--px_color_text_on_badge));
  }
}

.rejected.MuiChip-root {
  border-color: RGB(var(--px_color_badge_6));

  &.MuiChip-outlined,
  &.MuiChip-outlined:hover,
  &.MuiChip-outlined:focus {
    color: RGB(var(--px_color_badge_6));
    background-color: RGBA(var(--px_color_badge_6), var(--px_alpha_light));
  }

  &.px-filter-on,
  &.px-filter-on:hover,
  &.px-filter-on:focus {
    background-color: RGB(var(--px_color_badge_6));
    color: RGB(var(--px_color_text_on_badge));
  }
}

.expired.MuiChip-root {
  border-color: RGB(var(--px_color_badge_6));

  &.MuiChip-outlined,
  &.MuiChip-outlined:hover,
  &.MuiChip-outlined:focus {
    color: RGB(var(--px_color_badge_6));
    background-color: RGBA(var(--px_color_badge_6), var(--px_alpha_light));
  }

  &.px-filter-on,
  &.px-filter-on:hover,
  &.px-filter-on:focus {
    background-color: RGB(var(--px_color_badge_6));
    color: RGB(var(--px_color_text_on_badge));
  }
}

.countered.MuiChip-root {
  border-color: RGB(var(--px_color_badge_1));

  &.MuiChip-outlined,
  &.MuiChip-outlined:hover,
  &.MuiChip-outlined:focus {
    color: RGB(var(--px_color_badge_1));
    background-color: RGBA(var(--px_color_badge_1), var(--px_alpha_light));
  }

  &.px-filter-on,
  &.px-filter-on:hover,
  &.px-filter-on:focus {
    background-color: RGB(var(--px_color_badge_1));
    color: RGB(var(--px_color_text_on_badge));
  }
}

.pricedrop.MuiChip-root {
  border-color: RGB(var(--px_color_badge_2));

  &.MuiChip-outlined,
  &.MuiChip-outlined:hover,
  &.MuiChip-outlined:focus {
    color: RGB(var(--px_color_badge_2));
    background-color: RGBA(var(--px_color_badge_2), var(--px_alpha_light));
  }

  &.px-filter-on,
  &.px-filter-on:hover,
  &.px-filter-on:focus {
    background-color: RGB(var(--px_color_badge_2));
    color: RGB(var(--px_color_text_on_badge));
  }
}

.outofstock.MuiChip-root {
  border-color: RGB(var(--px_color_warning));

  &.MuiChip-outlined,
  &.MuiChip-outlined:hover,
  &.MuiChip-outlined:focus {
    color: RGB(var(--px_color_warning));
    background-color: RGBA(var(--px_color_warning), var(--px_alpha_light));
  }

  &.px-filter-on {
    background-color: RGB(var(--px_color_warning));
    color: RGB(var(--px_color_text_on_badge));
  }
}

@media screen and (min-width: 1024px) {
  .px-offset-bottom-mobile-view {
    &::after {
      height: 0;
    }
  }
}

amplify-authenticator {
  --container-align: top !important;
}
